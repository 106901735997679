import { useReducer, useRef, useState } from 'react';
import DropDown from 'components/NewLayout/Dropdown';
import type {
  PaymentPoDetails,
  PaymentScheduleForm,
  POModal,
  PoModalDetails,
  Vendor
} from 'api/types';
import type { DropdownOptionType } from 'components/inputs/Dropdown';
import { postPaymentSchedule } from 'api/index';
import toast from 'react-hot-toast';
import { usePaymentWorkspaceStore } from 'store/index';
import Validator from 'simple-react-validator';
import { getValidations } from 'utils/index';
import CustomDialog from 'components/NewLayout/Dialog';
import CustomButton from 'components/NewLayout/Button';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip
} from '@mui/material';
import AddCirlce from 'assets/images/add_circle.svg';
import CustomTextfield from 'components/NewLayout/Textfield';
import CheckCirleSuccess from 'assets/images/check_circle_success.svg';
import DeleteIcon from 'assets/images/delete-icon.svg';

export const StyledLegend = styled('div')(({ theme }) => ({
  fontSize: '16px',
  marginBottom: theme.spacing(1),
  width: 'max-content',
  background: '#fff',
  marginTop: '-29px !important',
  paddingLeft: '7px',
  paddingRight: '5px'
}));

export const paymentMethods: DropdownOptionType[] = [
  { id: 'ACH', value: 'ACH' },
  { id: 'Wire', value: 'Wire' },
  { id: 'Live Check', value: 'Live Check' },
  { id: 'Bill Pay', value: 'Bill Pay' },
  { id: 'Credit Card', value: 'Credit Card' }
];

interface tax_shipping {
  shippingAmount: string;
  taxAmount: string;
}

export default function PaymentScheduleModal({
  POModalData,
  taxAndshippingAmount,
  vendorBillingData,
  purchaseOrderId,
  selectedPoTotalPrice,
  selectedPoOtherBillTotal,
  selectedVendorPayment,
  paymentPoDetails,
  onClose,
  onComplete,
  createPurchaseOrderAndPaymentSchedule,
  onConfirmWarning
}: {
  POModalData?: POModal[];
  taxAndshippingAmount?: tax_shipping;
  vendorBillingData?: Vendor[];
  purchaseOrderId?: string;
  selectedPoTotalPrice?: string | number;
  selectedPoOtherBillTotal?: string;
  selectedVendorPayment?: string;
  paymentPoDetails: PaymentPoDetails;
  onClose: () => void;
  onComplete?: () => void;
  createPurchaseOrderAndPaymentSchedule?: (data: PaymentScheduleForm[]) => Promise<void>;
  onConfirmWarning?: (val: boolean) => Promise<void>;
}) {
  const initialPaymentScheduleObj = {
    index: 0,
    po_in_total: '',
    amount: undefined,
    due_date: '',
    payment_method: selectedVendorPayment
  };
  const [modalShow, setShowModal] = useState<boolean>(true);
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<PaymentScheduleForm[]>([initialPaymentScheduleObj]);
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [delayPaymentScheduleModal, setDelayPaymentScheduleModal] = useState<boolean>(false);
  const addPaymentScheduleItem = usePaymentWorkspaceStore((state) => state.addPaymentScheduleItem);
  const validator = useRef(new Validator(getValidations()));
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const theme = useTheme();

  let total = 0;
  POModalData?.forEach((d) => {
    total += parseFloat(d.price ?? '0') * Number(d?.quantity);
  });

  const taxAndShippingSum =
    Number(taxAndshippingAmount?.taxAmount || 0) +
    Number(taxAndshippingAmount?.shippingAmount || 0);

  const totalIncludingTaxAndShipping = (taxAndShippingSum + total).toFixed(2);

  const handleChange = (event, index, field) => {
    const { name, value } = event.target;
    if (field == 'amount' && Number(value) < 1) {
      toast.error('Value cannot be equal or less then 1', {
        position: 'top-center',
        id: 'less_than_one'
      });
      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          [field]: ''
        };
        return updatedData;
      });
      return;
    }

    setData((prevData) => {
      const updatedData = [...prevData];
      updatedData[index] = {
        ...updatedData[index],
        [field]: value
      };
      return updatedData;
    });

    if (name == `data.${index}.amount`) {
      setData((prevData) => {
        const updatedData = [...prevData];
        updatedData[index] = {
          ...updatedData[index],
          ['po_in_total']:
            selectedPoTotalPrice || totalIncludingTaxAndShipping
              ? (
                  (value /
                    Number(
                      selectedPoTotalPrice?.toString() || totalIncludingTaxAndShipping.toString()
                    )) *
                  100
                )
                  .toFixed(2)
                  .toString()
              : '0' + ' %'
        };
        return updatedData;
      });
    }
  };

  const submitForm = async () => {
    if (validator.current.allValid()) {
      setLoader(true);
      let total_amount = 0;
      data.map((item: PaymentScheduleForm, index) => {
        data[index].purchase_order_id = purchaseOrderId;
        data[index].status = 'Not Approved for Payment';
        total_amount += item.amount ? Number(item?.amount?.toString()) : 0;
      });

      if (
        total_amount + Number(selectedPoOtherBillTotal) >
        Number(selectedPoTotalPrice || totalIncludingTaxAndShipping)
      ) {
        toast.error(
          `Total of Bills can't be greater than Total PO Amount that is : ${
            selectedPoTotalPrice || totalIncludingTaxAndShipping
          }`,
          {
            position: 'top-center',
            id: 'values_lesser'
          }
        );
        setLoader(false);
      } else if (
        total_amount + Number(selectedPoOtherBillTotal) <
        Number(selectedPoTotalPrice || totalIncludingTaxAndShipping)
      ) {
        toast.error(
          `Total of Bills can't be lesser than Total PO Amount that is : ${
            selectedPoTotalPrice || totalIncludingTaxAndShipping
          }`,
          {
            position: 'top-center',
            id: 'values_greater'
          }
        );
        setLoader(false);
      } else {
        if (createPurchaseOrderAndPaymentSchedule) {
          createPurchaseOrderAndPaymentSchedule(data)
            .then(() => {
              setLoader(false);
              setShowModal(false);
              setSuccessModal(true);
            })
            .catch((error) => {
              console.error('Error during purchase order creation:', error);
              setLoader(false);
            });
        }
      }
    } else {
      validator.current.showMessages();
      forceUpdate();
    }
  };

  const handleAddPaymentSchedule = () => {
    const newIndex = data?.length;
    const newPaymentObj = { ...initialPaymentScheduleObj, index: newIndex };
    setData([...data, newPaymentObj]);
  };

  const handleDeletePaymentSchedule = (indexToDelete: number) => {
    setData((prevData) => prevData?.filter((item) => item.index !== indexToDelete));
  };

  const handleDelayPaymentScheduleCreation = () => {
    if (onConfirmWarning) {
      setLoader(true);
      onConfirmWarning(true)
        .then(() => {
          setLoader(false);
          setShowModal(false);
          setDelayPaymentScheduleModal(false);
          setSuccessModal(true);
        })
        .catch((error) => {
          console.error('Error during creation of delayed payment schedule:', error);
          setLoader(false);
        });
    }
  };

  validator.current.purgeFields();

  return (
    <>
      <CustomDialog
        title="Create Payment Schedule"
        open={modalShow}
        maxWidth="md"
        closeDialog={() => onClose()}
        loading={loader}
        content={
          <>
            <Box className="payment-modal-table">
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Purchase Order Number</TableCell>
                      <TableCell>Total PO Amount</TableCell>
                      <TableCell>Total Paid</TableCell>
                      <TableCell>Total Remaining</TableCell>
                      <TableCell>Total Scheduled</TableCell>
                      <TableCell>Vendor</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow
                      key={'payment-moda-table'}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell>{paymentPoDetails?.po_number || '00000'}</TableCell>
                      <TableCell component="th" scope="row">
                        {'$' +
                          (paymentPoDetails?.total_amount !== undefined
                            ? Number(paymentPoDetails.total_amount) + taxAndShippingSum
                            : Number(totalIncludingTaxAndShipping)
                          ).toFixed(2)}
                      </TableCell>
                      <TableCell>{'$' + (paymentPoDetails?.paid_amount || 0)}</TableCell>
                      <TableCell>
                        {'$' +
                          (paymentPoDetails?.remaining_amount !== undefined
                            ? Number(paymentPoDetails.remaining_amount) + taxAndShippingSum
                            : Number(totalIncludingTaxAndShipping)
                          ).toFixed(2)}
                      </TableCell>
                      <TableCell>{'$' + (paymentPoDetails?.scheduled_amount || 0)}</TableCell>
                      <TableCell>
                        {paymentPoDetails?.vendor_name ||
                          vendorBillingData?.[0]?.vendor_name ||
                          'No Vendor Name'}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Typography component="div" sx={{ marginBottom: '30px !important' }}>
              Create payment schedule by adding the following fields.
            </Typography>
            {data?.map((d, i) => (
              <Box
                key={i}
                component="form"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 2,
                  border: `1px solid #dadada`,
                  borderRadius: 1,
                  p: 2,
                  mb: 3
                }}>
                <fieldset style={{ borderColor: theme.palette.divider }}>
                  <StyledLegend>
                    Payment Schedule
                    <div
                      onClick={() => handleDeletePaymentSchedule(Number(d.index))}
                      style={{
                        cursor: 'pointer',
                        display: 'inline-block',
                        margin: '0 0 6px 12px'
                      }}>
                      <img
                        className="img-fluid link-icon"
                        src={DeleteIcon}
                        alt="Delete Icon"
                        // style={{
                        //   pointerEvents: d.disabled ? 'none' : 'auto'
                        // }}
                      />
                    </div>
                  </StyledLegend>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        type="number"
                        min="0"
                        labelText="Amount"
                        name={`data.${i}.amount`}
                        value={d.amount?.toString() ?? ''}
                        onChange={(event) => handleChange(event, i, 'amount')}
                        isRequired
                        maxlength={250}
                        validator={validator}
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        labelText="PO Total in %"
                        name={`data.${i}.po_in_total`}
                        value={d.po_in_total}
                        onChange={(event) => handleChange(event, i, 'po_in_total')}
                        isRequired={false}
                        disabled
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <CustomTextfield
                        validator={validator}
                        labelText="Due Date"
                        name={`data.${i}.due_date`}
                        value={d.due_date}
                        isRequired={true}
                        onChange={(event) => handleChange(event, i, 'due_date')}
                        type="date"
                        size="medium"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DropDown
                        label="Payment Method"
                        inputName={`data.${i}.payment_method`}
                        optionText="Select payment method"
                        value={d.payment_method || ''}
                        onChange={(event) => handleChange(event, i, 'payment_method')}
                        options={paymentMethods}
                        validator={validator}
                        required
                      />
                    </Grid>
                  </Grid>
                </fieldset>
              </Box>
            ))}
            <Typography
              component="div"
              sx={{ width: '100%', textAlign: 'center', marginTop: '30px !important' }}>
              <CustomButton
                onClick={handleAddPaymentSchedule}
                type="info"
                label={
                  <>
                    <img src={AddCirlce} style={{ marginRight: '10px' }} /> Add another payment
                  </>
                }
              />
            </Typography>
          </>
        }
        actions={
          <>
            <CustomButton onClick={() => onClose()} variant="outlined" label="Discard" />
            <CustomButton
              onClick={() => {
                setDelayPaymentScheduleModal(true);
                setShowModal(false);
              }}
              label="Delay Payment Schedule Creation"
            />
            <CustomButton
              disabled={!data?.length}
              onClick={() => submitForm()}
              label="Create Payment Schedule"
            />
          </>
        }
      />

      <CustomDialog
        title={
          <Typography component="div" sx={{ color: '#3cbe8e' }}>
            <img src={CheckCirleSuccess} />
            Success!
          </Typography>
        }
        open={successModal}
        maxWidth="xs"
        closeDialog={() => setSuccessModal(false)}
        loading={loader}
        content={<Typography sx={{ mb: 2 }}>Payment schedule created successfully!</Typography>}
        actions={
          <CustomButton
            onClick={() => {
              setSuccessModal(false);
              onClose();
              if (onComplete) onComplete();
            }}
            label="OK"
          />
        }
      />

      <CustomDialog
        title={
          <Typography component="div" sx={{ color: '#3cbe8e' }}>
            <img src={CheckCirleSuccess} />
            Delay Payment Schedule Creation
          </Typography>
        }
        open={delayPaymentScheduleModal}
        maxWidth="xs"
        closeDialog={() => {
          setShowModal(true);
          setDelayPaymentScheduleModal(false);
        }}
        loading={loader}
        content={
          <Typography sx={{ mb: 2 }}>
            You have chosen to delay the creation of a payment schedule. A bill for 100% of the
            amount will be created, the due date will be set to 1/1/2099, and the status will be set
            to &apos;Needs Allocation&apos;. You will need to allocate payments at a later time.
          </Typography>
        }
        actions={
          <>
            <CustomButton
              onClick={() => {
                setShowModal(true);
                setDelayPaymentScheduleModal(false);
              }}
              label="Go Back"
            />
            <CustomButton
              onClick={() => {
                handleDelayPaymentScheduleCreation();
              }}
              label="Proceed"
            />
          </>
        }
      />
    </>
  );
}
