import { Box, Typography } from '@mui/material';
import NoDoc from 'assets/images/NoDoc.svg';
import Loader from 'components/NewLayout/Loader';

const NoDocuments = ({
  text,
  isLoading,
  component
}: {
  text: string;
  isLoading?: boolean;
  component?: JSX.Element;
}) => (
  <Box>
    <Box
      sx={{
        border: '1px solid #000',
        height: '400px',
        borderRadius: '10px',
        backgroundColor: '#fff',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
      }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center'
        }}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Box>
              <Typography
                component={'img'}
                src={NoDoc}
                sx={{
                  width: 'fit-content',
                  maxHeight: '250px'
                }}
              />
            </Box>
            <Typography sx={{ fontWeight: 'bold', fontSize: '18px', marginTop: '10px' }}>
              {text}
            </Typography>
            {component}
          </>
        )}
      </Box>
    </Box>
  </Box>
);

export default NoDocuments;
