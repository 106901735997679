import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BrushIcon from '@mui/icons-material/Brush';
import CloseIcon from '@mui/icons-material/Close';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { AppBar, Box, debounce, IconButton, Toolbar, Tooltip, Typography } from '@mui/material';
import { estimateItem } from 'api/index';
import type {
  DrawingLayerProps,
  LineData,
  PatchEstimateItemRequest,
  RuntimeCanvasState,
  RuntimeImageData,
  SerializedCanvasState,
  SerializedImageData,
  TextData
} from 'api/types';
import { useCanvasContext } from 'contexts/canvasContext';
import type Konva from 'konva';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { Image, Layer, Line, Stage, Text, Transformer } from 'react-konva';

const cursors = {
  pencil: 'crosshair',
  eraser: 'cell',
  text: 'text',
  select: 'pointer'
};

const DrawingLayer = React.memo(
  ({
    lines,
    texts,
    images,
    tool,
    handleLineClick,
    handleTextClick,
    handleImageClick,
    handleTextDrag,
    setSelectedItem,
    setImages,
    setTexts,
    editingTextId,
    selectedItem,
    transformerRef
  }: DrawingLayerProps) => (
    <Layer>
      {images.map((imgObj) => (
        <Image
          key={imgObj.id}
          id={String(imgObj.id)}
          image={imgObj.image}
          x={imgObj.x}
          y={imgObj.y}
          width={imgObj.width}
          height={imgObj.height}
          draggable={tool === 'select'}
          onClick={(e) => handleImageClick && handleImageClick(imgObj, e.target as Konva.Image)}
          onDragEnd={(e) => {
            const newX = e.target.x();
            const newY = e.target.y();

            setImages((prevImages) =>
              prevImages.map((img) => (img.id === imgObj.id ? { ...img, x: newX, y: newY } : img))
            );
          }}
          onTransformEnd={(e) => {
            const node = e.target;

            setImages((prevImages) =>
              prevImages.map((img) =>
                img.id === imgObj.id
                  ? {
                      ...img,
                      x: node.x(),
                      y: node.y(),
                      width: node.width() * node.scaleX(),
                      height: node.height() * node.scaleY()
                    }
                  : img
              )
            );

            node.scaleX(1);
            node.scaleY(1);
          }}
        />
      ))}

      {texts.map((text) => {
        if (editingTextId === text.id) {
          return null;
        }
        const handleTextAction = (e: Konva.KonvaEventObject<MouseEvent>, textId: number) => {
          if (tool === 'text') {
            handleTextClick(textId);
          } else if (tool === 'select') {
            setSelectedItem(e.target);
          }
        };

        return (
          <Text
            key={text.id}
            id={String(text.id)}
            text={text.text}
            x={text.x}
            y={text.y}
            fontSize={text.fontSize}
            fontFamily="arial"
            fontStyle="bold"
            draggable={tool === 'select'}
            onDragEnd={(e) =>
              handleTextDrag(text.id, {
                x: e.target.x(),
                y: e.target.y()
              })
            }
            onTransformEnd={(e) => {
              const node = e.target;

              const newFontSize = text.fontSize * node.scaleY();

              setTexts((prevTexts) =>
                prevTexts.map((t) =>
                  t.id === text.id
                    ? {
                        ...t,
                        x: node.x(),
                        y: node.y(),
                        fontSize: newFontSize
                      }
                    : t
                )
              );

              node.scaleX(1);
              node.scaleY(1);
            }}
            onClick={(e) => handleTextAction(e, text.id)}
          />
        );
      })}

      {lines.map((line) => (
        <Line
          key={line.id}
          points={line.points}
          stroke="#000"
          strokeWidth={2}
          tension={0.5}
          lineCap="round"
          lineJoin="round"
          hitStrokeWidth={15}
          onClick={() => handleLineClick(line.id)}
        />
      ))}

      {selectedItem && (
        <Transformer
          ref={transformerRef}
          boundBoxFunc={(oldBox, newBox) => {
            newBox.width < 5 || newBox.height < 5 ? oldBox : newBox;
            if (newBox.width < 20) return oldBox;
            return newBox;
          }}
        />
      )}
    </Layer>
  )
);

DrawingLayer.displayName = 'DrawingLayer';

const serializeImage = (imageData: RuntimeImageData): SerializedImageData => ({
  id: imageData.id,
  src: imageData.image.src,
  x: imageData.x,
  y: imageData.y,
  width: imageData.width,
  height: imageData.height
});

const serializeText = (textData: TextData): TextData => ({
  id: textData.id,
  text: textData.text,
  x: textData.x,
  y: textData.y,
  fontSize: textData.fontSize
});

const serializeCanvasState = (
  lines: LineData[],
  texts: TextData[],
  images: RuntimeImageData[],
  stageRef: React.RefObject<Konva.Stage>
): SerializedCanvasState => {
  const stage = stageRef.current;
  const stageConfig = {
    scale: { x: stage?.scaleX() || 1, y: stage?.scaleY() || 1 },
    position: { x: stage?.x() || 0, y: stage?.y() || 0 }
  };

  return {
    lines,
    texts: texts.map(serializeText),
    images: images.map(serializeImage),
    stageConfig
  };
};

const loadImage = (src: string): Promise<HTMLImageElement> => {
  {
    return new Promise((resolve, reject) => {
      const img = new window.Image();
      img.crossOrigin = 'anonymous';
      img.onload = () => resolve(img);
      img.onerror = reject;
      img.src = src;
    });
  }
};

export const deserializeCanvasState = async (
  serializedState: SerializedCanvasState
): Promise<RuntimeCanvasState> => {
  const reconstructedImages = await Promise.all(
    (serializedState?.images || []).map(
      async (imgData): Promise<RuntimeImageData> => ({
        id: imgData.id,
        image: await loadImage(imgData.src),
        x: imgData.x,
        y: imgData.y,
        width: imgData.width,
        height: imgData.height
      })
    )
  );

  const reconstructedTexts = (serializedState?.texts || []).map(
    (textData): TextData => ({
      id: textData.id,
      text: textData.text,
      x: textData.x,
      y: textData.y,
      fontSize: textData.fontSize
    })
  );

  return {
    lines: serializedState?.lines || [],
    texts: reconstructedTexts,
    images: reconstructedImages,
    stageConfig: serializedState?.stageConfig || {
      scale: { x: 1, y: 1 },
      position: { x: 0, y: 0 }
    }
  };
};

const updateCanvasState = async (
  estimateItemId: number,
  canvasState: SerializedCanvasState
): Promise<void> => {
  const body: PatchEstimateItemRequest = {
    http_method: 'PATCH',
    data: {
      estimate_item_id: estimateItemId,
      canvas: canvasState
    }
  };

  try {
    await estimateItem(body);
    // console.log('body', body);
    // toast.success('Canvas state saved successfully!', {
    //   position: 'top-center'
    // });
  } catch (err) {
    console.error('Error saving canvas state:', err);
    toast.error('Failed to save canvas state', {
      position: 'top-center'
    });
  }
};

const Canvas = ({ estimateItemId }: { estimateItemId: number }) => {
  const {
    tool,
    setTool,
    lines,
    setLines,
    texts,
    setTexts,
    images,
    setImages,
    selectedItem,
    setSelectedItem,
    isDrawing,
    setIsDrawing,
    editingTextId,
    setEditingTextId,
    textInput,
    setTextInput,
    isPanning,
    setIsPanning,
    hasStageBeenSet,
    stageRef,
    transformerRef,
    inputRef,
    handleCanvasVisibility
  } = useCanvasContext();

  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [isDraggingOver, setIsDraggingOver] = useState(false);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true);
      }
    };

    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const debouncedSave = useCallback(
    debounce((state: SerializedCanvasState) => {
      if (hasStageBeenSet) {
        updateCanvasState(estimateItemId, state);
      }
    }, 1000),
    [estimateItemId, hasStageBeenSet]
  );

  useEffect(() => () => debouncedSave.clear(), [debouncedSave]);

  useEffect(() => {
    if (!hasStageBeenSet) return;

    const canvasState = serializeCanvasState(lines, texts, images, stageRef);
    if (canvasState.lines.length || canvasState.texts.length || canvasState.images.length) {
      debouncedSave(canvasState);
    }
  }, [lines, texts, images, debouncedSave, hasStageBeenSet]);

  useEffect(() => {
    if (transformerRef.current && selectedItem) {
      transformerRef.current.nodes([selectedItem]);
      transformerRef.current.getLayer()?.batchDraw();
    }
  }, [selectedItem]);

  const stageDimensions = useMemo(
    () => ({
      width: window.innerWidth - 64,
      height: window.innerHeight - 56
    }),
    []
  );

  const handleMouseDown = useCallback(
    (e) => {
      const stage = stageRef.current;
      if (!stage) return;

      const pos = stage.getPointerPosition();
      const transformedPos = stage.getRelativePointerPosition();

      let clickedNode;
      let newText;
      if (tool === 'select') {
        if (e.evt) {
          setIsPanning(true);
          return;
        }
      } else {
        if (e.evt.ctrlKey) {
          setIsPanning(true);
          return;
        }
      }

      switch (tool) {
        case 'select':
          clickedNode = stage.getIntersection(pos);

          if (clickedNode && ['Text', 'Image'].includes(clickedNode.getClassName())) {
            setSelectedItem(clickedNode);
          } else {
            setSelectedItem(null);
          }
          break;
        case 'pencil':
          setIsDrawing(true);
          setLines((prev) => [
            ...prev,
            {
              id: Date.now(),
              points: [transformedPos.x, transformedPos.y]
            }
          ]);
          break;

        case 'text':
          newText = {
            id: Date.now(),
            text: '',
            x: transformedPos.x,
            y: transformedPos.y,
            fontSize: 20
          };
          setTexts((prev) => [...prev, newText]);
          setEditingTextId(newText.id);
          setTextInput('');
          break;
      }
    },
    [
      tool,
      stageRef,
      setIsPanning,
      setSelectedItem,
      setTexts,
      setEditingTextId,
      setTextInput,
      isCtrlPressed
    ]
  );

  const handleMouseMove = useCallback(
    (e) => {
      const stage = stageRef.current;
      if (!stage) return;

      if (isPanning) {
        const newPos = {
          x: stage.x() + e.evt.movementX,
          y: stage.y() + e.evt.movementY
        };
        stage.position(newPos);
        return;
      }

      if (!isDrawing || tool !== 'pencil') return;

      const transformedPos = stage.getRelativePointerPosition();
      setLines((prev) => {
        const lastLine = prev[prev.length - 1];
        const updatedLine = {
          ...lastLine,
          points: [...lastLine.points, transformedPos.x, transformedPos.y]
        };
        return [...prev.slice(0, -1), updatedLine];
      });
    },
    [isPanning, isDrawing, tool, stageRef]
  );

  const handleMouseUp = useCallback(() => {
    if (isPanning) {
      setIsPanning(false);
      return;
    }

    if (tool === 'pencil') {
      setIsDrawing(false);
    }
  }, [isPanning, tool, setIsPanning, setIsDrawing]);

  const handleZoom = useCallback(
    (e) => {
      if (!e.ctrlKey) return;

      e.preventDefault();
      const stage = stageRef.current;
      if (!stage) return;

      const oldScale = stage.scaleX();
      const scaleBy = 1.1;
      const newScale = e.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

      const pointer = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointer.x - stage.x()) / oldScale,
        y: (pointer.y - stage.y()) / oldScale
      };

      const newPos = {
        x: pointer.x - mousePointTo.x * newScale,
        y: pointer.y - mousePointTo.y * newScale
      };

      stage.scale({ x: newScale, y: newScale });
      stage.position(newPos);
      stage.batchDraw();
    },
    [stageRef]
  );

  useEffect(() => {
    const stage = stageRef.current;
    if (!stage) return;

    const container = stage.container();
    container.addEventListener('wheel', handleZoom);
    return () => container.removeEventListener('wheel', handleZoom);
  }, [handleZoom]);

  const handleLineClick = useCallback(
    (id) => {
      if (tool === 'eraser') {
        setLines((prev) => prev.filter((line) => line.id !== id));
      }
    },
    [tool, setLines]
  );

  const handleDelete = useCallback(() => {
    if (!selectedItem) {
      console.warn('No item selected for deletion');
      return;
    }

    const className = selectedItem.getClassName?.();

    if (className === 'Image') {
      setImages((prevImages) => {
        const updatedImages = prevImages.filter(
          (imgObj) => String(imgObj.id) !== selectedItem.id()
        );
        return updatedImages;
      });
    } else if (className === 'Text') {
      setTexts((prevTexts) => {
        const updatedTexts = prevTexts.filter((text) => String(text.id) !== selectedItem.id());
        return updatedTexts;
      });
    } else {
      console.warn('Selected item is not deletable');
    }

    setSelectedItem(null);
  }, [selectedItem, setImages, setTexts, setSelectedItem]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Delete') {
        handleDelete();
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleDelete]);

  const handleTextClick = useCallback(
    (id) => {
      setEditingTextId(id);
      const textItem = texts.find((text) => text.id === id);
      if (textItem) {
        setTextInput(textItem.text);
      }
    },
    [texts, setEditingTextId, setTextInput]
  );

  const handleTextDrag = useCallback(
    (id, newPosition) => {
      setTexts((prevTexts) =>
        prevTexts.map((text) =>
          text.id === id ? { ...text, x: newPosition.x, y: newPosition.y } : text
        )
      );
    },
    [setTexts]
  );

  const handleTextInputChange = (e) => {
    const updatedText = e.target.value;
    setTextInput(updatedText);

    setTexts((prevTexts) =>
      prevTexts.map((text) => (text.id === editingTextId ? { ...text, text: updatedText } : text))
    );
  };

  const handleTextBlur = () => {
    setEditingTextId(null);
  };

  const handleImageClick = useCallback(
    (imgObj, node) => {
      setSelectedItem(node);
    },
    [setSelectedItem]
  );

  const handleDragEnter = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDraggingOver(false);
    const file = e.dataTransfer.files[0];

    if (file && file.type.startsWith('image/')) {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          const image = new window.Image();
          image.src = reader.result as string;
          image.onload = () => {
            const imgObj = {
              id: Date.now(),
              image,
              x: e.clientX,
              y: e.clientY,
              width: image.width / 5,
              height: image.height / 5,
              node: null
            };
            setImages([...images, imgObj]);
          };
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleStageClick = useCallback(
    (e) => {
      if (e.target === e.target.getStage()) {
        setSelectedItem(null);
      }
    },
    [setSelectedItem]
  );

  useEffect(() => {
    if (editingTextId) {
      setTimeout(() => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }, 0);
    }
  }, [editingTextId]);

  const getCursorStyle = () => {
    if (isCtrlPressed) return 'grab';
    if (!tool) return 'default';
    return cursors[tool] || 'default';
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '100vh',
        overflow: 'hidden',
        border: '1px solid #ccc',
        borderRadius: '4px'
      }}>
      <AppBar position="static" sx={{ width: '64px', height: '100vh' }}>
        <Toolbar sx={{ flexDirection: 'column', gap: 2, padding: 1 }}>
          <Tooltip title="Pencil">
            <IconButton
              color={tool === 'pencil' ? 'inherit' : 'default'}
              onClick={() => {
                if (tool === 'pencil') {
                  setTool(undefined);
                } else {
                  setTool('pencil');
                  setEditingTextId(null);
                  setSelectedItem(null);
                }
              }}>
              <BrushIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Eraser">
            <IconButton
              color={tool === 'eraser' ? 'inherit' : 'default'}
              onClick={() => {
                if (tool === 'eraser') {
                  setTool(undefined);
                } else {
                  setTool('eraser');
                  setEditingTextId(null);
                  setSelectedItem(null);
                }
              }}>
              <AutoFixHighIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Text">
            <IconButton
              color={tool === 'text' ? 'inherit' : 'default'}
              onClick={() => {
                if (tool === 'text') {
                  setTool(undefined);
                } else {
                  setTool('text');
                  setSelectedItem(null);
                  setIsPanning(false);
                }
              }}>
              <TextFieldsIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Select">
            <IconButton
              color={tool === 'select' ? 'inherit' : 'default'}
              onClick={() => {
                if (tool === 'select') {
                  setTool(undefined);
                } else {
                  setTool('select');
                  setEditingTextId(null);
                  setIsPanning(false);
                }
              }}>
              <TouchAppIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>

      <Box sx={{ flex: 1, backgroundColor: '#f5f5f5', position: 'relative', overflow: 'hidden' }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '8px 16px',
            borderBottom: '1px solid #ddd',
            backgroundColor: '#fff'
          }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 500,
              textAlign: 'center',
              flex: 1
            }}>
            ESTIMATE ITEM CANVAS
          </Typography>
          <IconButton onClick={() => handleCanvasVisibility(null)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Box
          sx={{
            width: '100%',
            height: 'calc(100vh - 56px)',
            position: 'relative',
            cursor: getCursorStyle()
          }}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          onDragOver={handleDragOver}>
          {isDraggingOver && (
            <Box
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.1)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 1000,
                pointerEvents: 'none'
              }}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  color: 'primary.main'
                }}>
                <DragIndicatorIcon sx={{ fontSize: 100, opacity: 0.7 }} />
                <Typography variant="h6">Drop image here</Typography>
              </Box>
            </Box>
          )}
          <Stage
            {...stageDimensions}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onClick={handleStageClick}
            style={{ cursor: getCursorStyle() }}
            ref={stageRef}>
            <DrawingLayer
              lines={lines}
              texts={texts}
              images={images}
              tool={tool}
              handleLineClick={handleLineClick}
              handleTextClick={handleTextClick}
              handleTextDrag={handleTextDrag}
              setSelectedItem={setSelectedItem}
              setImages={setImages}
              setTexts={setTexts}
              editingTextId={editingTextId}
              handleImageClick={tool === 'select' ? handleImageClick : undefined}
              selectedItem={selectedItem}
              transformerRef={transformerRef}
            />
          </Stage>
          {editingTextId && (
            <input
              ref={inputRef}
              type="text"
              value={textInput}
              onChange={handleTextInputChange}
              onBlur={handleTextBlur}
              style={{
                position: 'absolute',
                top:
                  texts.find((text) => text.id === editingTextId)?.y * stageRef.current.scaleY() +
                  stageRef.current.position().y,
                left:
                  texts.find((text) => text.id === editingTextId)?.x * stageRef.current.scaleX() +
                  stageRef.current.position().x,
                transform: 'translate(-50%, -50%)',
                pointerEvents: 'auto'
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Canvas;
