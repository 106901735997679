import React, { useEffect, useState } from 'react';
import config from 'config/index';
import AWS from 'aws-sdk';
import CustomDialog from './NewLayout/Dialog';
import { Typography } from '@mui/material';
import NoDocIcon from 'assets/images/no-doc.svg';
import CustomButton from './NewLayout/Button';

export default function AttachmentsModal({
  attachements,
  onClose
}: {
  attachements?: string;
  onClose: () => void;
}) {
  const [imageUrl, setImageUrl] = useState('');
  const [loader, setLoader] = useState<boolean>(false);

  AWS.config.update({
    accessKeyId: localStorage.getItem('accessKeyId') ?? '',
    secretAccessKey: localStorage.getItem('secretAccessKey') ?? '',
    sessionToken: localStorage.getItem('sessionToken') ?? '',
    region: config.awsRegion
  });

  const s3 = new AWS.S3();

  useEffect(() => {
    setLoader(true);
    if (attachements == 'No File Selected' || attachements?.length == 0 || attachements == null) {
      setLoader(false);
    } else {
      const fetchImage = async () => {
        try {
          const params = {
            Bucket: config.awsS3AssetsBucketName,
            Key: attachements ?? ''
          };

          s3.getObject(params, (err, data) => {
            if (err) {
              console.error(err);
            } else if (data.Body) {
              const imageDataUrl = `data:image/jpeg;base64,${data?.Body?.toString('base64')}`;
              setImageUrl(imageDataUrl);
              setLoader(false);
            } else {
              console.error('No data.Body found');
            }
          });
        } catch (error) {
          console.error('Error fetching image from S3:', error);
        }
      };

      fetchImage();
    }
  }, []);

  return (
    <>
      <CustomDialog
        open={true}
        loading={loader}
        maxWidth="sm"
        closeDialog={() => onClose()}
        title="Attachments"
        content={
          attachements == 'No File Selected' ||
          attachements?.length == 0 ||
          attachements == null ? (
            <Typography component="div" sx={{ width: '100%', textAlign: 'center' }}>
              <img src={NoDocIcon} style={{ width: 'max-content' }} />
              <Typography component="div" sx={{ fontSize: '32px', color: '#c83360' }}>
                SORRY!
              </Typography>
              <Typography>We Haven’t Found Any Attachment</Typography>
            </Typography>
          ) : (
            <img src={imageUrl} className="img-fluid data-table-img" alt="Image placeholder" />
          )
        }
        actions={<CustomButton label="OK" onClick={() => onClose()} />}
      />
    </>
  );
}
