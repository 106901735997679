import { Box, IconButton, Typography } from '@mui/material';
import editIcon from 'assets/images/edit-icon.svg';
import stylusNoteIcon from 'assets/images/stylus_note.svg';
import CustomButton from 'components/NewLayout/Button';
import InfoBadge from 'components/NewLayout/InfoBadge';
import { useCanvasContext } from 'contexts/canvasContext';

const TitleHeader = ({
  title,
  quantity,
  totalCost
}: {
  title: string;
  quantity: number;
  totalCost: number;
}) => {
  const { handleCanvasVisibility } = useCanvasContext();
  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography
            variant="h6"
            sx={{
              fontFamily: 'Poppins',
              fontSize: '28px',
              fontWeight: 500,
              lineHeight: '42px',
              textAlign: 'left',
              textUnderlinePosition: 'from-font',
              textDecorationSkipInk: 'none'
            }}>
            {title}
          </Typography>

          <IconButton>
            <img src={editIcon} width={20} />
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex' }}>
          <InfoBadge label="Quantity" badgeValue={quantity} />
          <InfoBadge label="Total Cost" badgeValue={`$${totalCost.toFixed(2)}`} />
          <CustomButton
            sx={{
              border: '1px dashed #335d87',
              backgroundColor: '#fff',
              marginLeft: '5px',
              borderRadius: '5px',
              display: 'flex',
              alignItems: 'center',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.1)' }
            }}
            onClick={() => handleCanvasVisibility('global')}
            label={
              <>
                <img src={stylusNoteIcon} style={{ marginRight: '0px' }} />
              </>
            }
          />
        </Box>
      </Box>
    </>
  );
};

export default TitleHeader;
