import CustomTextfield from 'components/NewLayout/Textfield';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';

export default function GooglePlacesAutocompleteComp({
  labelText,
  isRequired,
  onChange,
  name,
  disabled,
  value,
  onBlur,
  fromEditableTableCell,
  getAddressComponent,
  validator
}: {
  labelText?: string;
  name: string;
  onChange?: any;
  isRequired?: boolean;
  disabled?: boolean;
  value: string;
  onBlur?: any;
  fromEditableTableCell?: boolean;
  getAddressComponent?: any;
  validator?: any;
}) {
  const [val, setVal] = useState<string>(value || '');
  const [selectedPlace, setSelectedPlace] = useState<string>('');
  const [addrComponents, setAddrComponents] = useState<any>(null);
  const [count, setCount] = useState<number>(0);

  useEffect(() => {
    if (count < 3) {
      setVal(value);
      setCount(count + 1);
    }
  }, [value]);

  useEffect(() => {
    if (!disabled) {
      const autocompleteInput: HTMLElement | null = document.getElementById(name);
      if (autocompleteInput) {
        const autocomplete = new google.maps.places.Autocomplete(autocompleteInput, {
          types: ['address'],
          componentRestrictions: { country: 'us' }
        });
        autocomplete.addListener('place_changed', () => {
          const place = autocomplete.getPlace();
          const addressComponents = place.address_components || [];
          let streetNumber = '';
          let route = '';
          let addressLine1 = '';

          addressComponents.forEach((component) => {
            if (component.types.includes('street_number')) {
              streetNumber = component.long_name;
            }
            if (component.types.includes('route')) {
              route = component.long_name;
            }
          });

          addressLine1 = `${streetNumber} ${route}`.trim();

          if (!streetNumber || !route) {
            toast.error(
              'Invalid Address: Please enter a complete address, including a street name and number. City-only entries are not accepted.',
              {
                position: 'top-center',
                id: 'invalid_address'
              }
            );
            setVal('');
            return;
          }

          const formattedAddress = place.formatted_address || '';
          setVal(formattedAddress);
          setSelectedPlace(formattedAddress);

          if (fromEditableTableCell && onChange) {
            onChange({ target: { name, value: formattedAddress } });
          }

          const googleComponents = [
            { googleComponent: `sublocality_level_1`, id: `city` },
            { googleComponent: `locality`, id: `city` },
            { googleComponent: `administrative_area_level_1`, id: `state` },
            { googleComponent: `postal_code`, id: `postalCode` },
            { googleComponent: `country`, id: `country` }
          ];

          const obj: Record<string, string> = {};

          googleComponents.forEach((formMap) => {
            const matchingComponent = addressComponents.find((component) =>
              component.types.includes(formMap.googleComponent)
            );
            if (matchingComponent) {
              obj[formMap.id] = matchingComponent.long_name;
            }
          });

          setAddrComponents({ ...obj, addressLine1 });
        });

        return () => {
          if (autocompleteInput) {
            autocompleteInput.removeEventListener('place_changed', () => console.log('removed'));
          }
        };
      }
    }
  }, [disabled]);

  useEffect(() => {
    if (onChange) {
      onChange({ target: { name, value: val } });
    }
  }, [val]);

  useEffect(() => {
    if (addrComponents && getAddressComponent) {
      getAddressComponent(addrComponents);
    }
  }, [addrComponents]);

  const handleChange = ({ target: { value } }: any) => {
    setVal(value);
  };

  useEffect(() => {
    if (selectedPlace) {
      if (onChange) {
        onChange({ target: { name, value: selectedPlace } });
      }
      setTimeout(() => {
        if (onBlur) {
          const autocompleteInput = document.getElementById(name);
          onBlur({
            ...autocompleteInput,
            target: { name, value: selectedPlace }
          });
        }
      }, 500);
    }
  }, [selectedPlace]);

  const handleBlur = ({ target: { value } }) => {
    if (!value && onBlur) {
      const autocompleteInput = document.getElementById(name);
      onBlur({
        ...autocompleteInput,
        target: { name, value: '' }
      });
    }
  };

  return (
    <div>
      {disabled ? (
        <CustomTextfield value={value} name="location" disabled={disabled} labelText={labelText} />
      ) : (
        <CustomTextfield
          value={val}
          id={name}
          name={name}
          onBlur={handleBlur}
          disabled={disabled}
          placeholder={'Search for a place'}
          onChange={handleChange}
          labelText={labelText}
          isRequired={isRequired}
          size="medium"
          validator={validator}
        />
      )}
    </div>
  );
}
