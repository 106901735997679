import React from 'react';
import { Routes, Route, Outlet, Navigate } from 'react-router-dom';
import ProtectedRoute from './protectedRoute';
import PaymentWorkspace from 'pages/PaymentWorkspace';
import EstimateWorkspace from 'pages/JobEstimation/EstimateWorspace';
import EstimateItemWorkspace from 'pages/JobEstimation/EstimateItemWorksapce';
const Home = React.lazy(() => import('pages/NewHome/index'));
const RequestWorkspace = React.lazy(() => import('pages/RequestWorkspace'));
const ProcurementWorkspace = React.lazy(() => import('pages/ProcurementWorkspace'));
const OCRPOWorkspace = React.lazy(() => import('pages/OCRPOWorkspace'));
const ReceivingWorkspace = React.lazy(() => import('pages/ReceivingWorkspace'));
const DriverWorkspace = React.lazy(() => import('pages/DriverWorkspace'));
const TransportationWorkspace = React.lazy(() => import('pages/TransportationWorkspace'));
const CreditCardWorkspace = React.lazy(() => import('pages/AdminHub/CreditCardWorkspace'));
const PeopleWorkspace = React.lazy(() => import('pages/AdminHub/PeopleWorkspace'));
const VehicleWorkspace = React.lazy(() => import('pages/AdminHub/VehicleWorkspace'));
const VendorWorkspace = React.lazy(() => import('pages/AdminHub/VendorWorkspace'));
const ProjectWorkspace = React.lazy(() => import('pages/AdminHub/ProjectWorkspace'));
const CustomerWorkspace = React.lazy(() => import('pages/AdminHub/CustomerWorkspace'));
const IntegrationWorkspace = React.lazy(() => import('pages/AdminHub/IntegrationWorkspace'));
const Analytics = React.lazy(() => import('pages/Analytics'));
const PunchForm = React.lazy(() => import('pages/TimeTrackingAndAttendence/PunchForm'));
const TimeWorkspace = React.lazy(() => import('pages/TimeTrackingAndAttendence/TimeWorkspace'));
const Help = React.lazy(() => import('pages/Help'));

export default function RoutesComp() {
  return (
    <Routes>
      <Route path="home" element={<Home />} />
      {/* <Route path="" element={<Home />} /> */}
      <Route path="/" element={<Navigate replace to="/home" />} />
      <Route path="/help" element={<Help />} />
      {/* add unprotected routes here */}
      <Route path="/" element={<ProtectedRoute />}>
        {/* add protected routes here */}
        <Route path="analytics" element={<Analytics />} />
        <Route path="order-requests" element={<RequestWorkspace />} />
        <Route path="procurement-workspace" element={<ProcurementWorkspace />} />
        <Route path="ocr-po-workspace" element={<OCRPOWorkspace />} />
        <Route path="receiving-workspace" element={<ReceivingWorkspace />} />
        <Route path="driver-workspace" element={<DriverWorkspace />} />
        <Route path="transportation-workspace" element={<TransportationWorkspace />} />
        <Route path="payment-workspace" element={<PaymentWorkspace />} />
        <Route path="admin-hub" element={<Outlet />}>
          <Route path="people-workspace" element={<PeopleWorkspace />} />
          <Route path="creditcard-workspace" element={<CreditCardWorkspace />} />
          <Route path="vehicle-workspace" element={<VehicleWorkspace />} />
          <Route path="vendor-workspace" element={<VendorWorkspace />} />
          <Route path="job-workspace" element={<ProjectWorkspace />} />
          <Route path="customer-workspace" element={<CustomerWorkspace />} />
          <Route path="integration-workspace" element={<IntegrationWorkspace />} />
        </Route>
        <Route path="time-tracking" element={<Outlet />}>
          <Route path="punch-form" element={<PunchForm />} />
          <Route path="time-workspace" element={<TimeWorkspace />} />
        </Route>
        <Route path="job-estimation" element={<Outlet />}>
          <Route path="estimate-workspace" element={<EstimateWorkspace />} />
          <Route path="estimate-item-workspace" element={<EstimateItemWorkspace />} />
        </Route>
      </Route>
    </Routes>
  );
}
