import { canvasVisibilityInitialObj } from 'pages/JobEstimation/EstimateItemWorksapce';
import type { ReactNode } from 'react';
import React, { createContext, useContext, useRef, useState } from 'react';
import type { CanvasVisibilityState } from 'utils/types';

interface CanvasContextProps {
  canvasVisibility: CanvasVisibilityState;
  setCanvasVisibility: React.Dispatch<React.SetStateAction<CanvasVisibilityState>>;
  tool: string | undefined;
  setTool: React.Dispatch<React.SetStateAction<string | undefined>>;
  lines: any[];
  setLines: React.Dispatch<React.SetStateAction<any[]>>;
  texts: any[];
  setTexts: React.Dispatch<React.SetStateAction<any[]>>;
  images: any[];
  setImages: React.Dispatch<React.SetStateAction<any[]>>;
  selectedItem: any;
  setSelectedItem: React.Dispatch<React.SetStateAction<any>>;
  isDrawing: boolean;
  setIsDrawing: React.Dispatch<React.SetStateAction<boolean>>;
  editingTextId: string | null;
  setEditingTextId: React.Dispatch<React.SetStateAction<string | null>>;
  textInput: string;
  setTextInput: React.Dispatch<React.SetStateAction<string>>;
  isPanning: boolean;
  setIsPanning: React.Dispatch<React.SetStateAction<boolean>>;
  hasStageBeenSet: boolean;
  setHasStageBeenSet: React.Dispatch<React.SetStateAction<boolean>>;
  stageRef: React.RefObject<any>;
  inputRef: React.RefObject<any>;
  transformerRef: React.RefObject<any>;
  handleCanvasVisibility: (region: keyof CanvasVisibilityState | null) => void;
}

export const CanvasContext = createContext<CanvasContextProps>({} as CanvasContextProps);

export const useCanvasContext = () => {
  const context = useContext(CanvasContext);
  if (!context) {
    throw new Error('useCanvasContext must be used within a CanvasProvider');
  }
  return context;
};

interface CanvasProviderProps {
  children: ReactNode;
}

export const CanvasProvider: React.FC<CanvasProviderProps> = ({ children }) => {
  const [canvasVisibility, setCanvasVisibility] = useState<CanvasVisibilityState>(
    canvasVisibilityInitialObj
  );
  const [tool, setTool] = useState<string | undefined>();
  const [lines, setLines] = useState<any[]>([]);
  const [texts, setTexts] = useState<any[]>([]);
  const [images, setImages] = useState<any[]>([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [editingTextId, setEditingTextId] = useState<string | null>(null);
  const [textInput, setTextInput] = useState('');
  const [isPanning, setIsPanning] = useState(false);
  const [hasStageBeenSet, setHasStageBeenSet] = useState(false);

  const stageRef = useRef<any>(null);
  const inputRef = useRef<any>(null);
  const transformerRef = useRef<any>(null);

  const handleCanvasVisibility = (region: keyof CanvasVisibilityState | null) => {
    if (region) {
      setCanvasVisibility((prevState) => {
        const newState = Object.keys(prevState).reduce<CanvasVisibilityState>((acc, key) => {
          acc[key as keyof CanvasVisibilityState] = key === region;
          return acc;
        }, {} as CanvasVisibilityState);
        return newState;
      });
    } else {
      setCanvasVisibility(canvasVisibilityInitialObj);
    }
  };

  const contextValue: CanvasContextProps = {
    canvasVisibility,
    setCanvasVisibility,
    tool,
    setTool,
    lines,
    setLines,
    texts,
    setTexts,
    images,
    setImages,
    selectedItem,
    setSelectedItem,
    isDrawing,
    setIsDrawing,
    editingTextId,
    setEditingTextId,
    textInput,
    setTextInput,
    isPanning,
    setIsPanning,
    hasStageBeenSet,
    setHasStageBeenSet,
    stageRef,
    inputRef,
    transformerRef,
    handleCanvasVisibility
  };

  return <CanvasContext.Provider value={contextValue}>{children}</CanvasContext.Provider>;
};
