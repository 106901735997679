import type { ChangeEvent } from 'react';
import React, { useEffect, useRef, useState } from 'react';
import {
  HOURS_VALIDATION_REGEX,
  MAX_10_DIGITS_ONE_DECIMAL_REGEX,
  MAX_11_DIGITS_TWO_DECIMALS_REGEX
} from 'utils/index';
import DropDown from './inputs/Dropdown';
import GooglePlacesAutocompleteComp from './inputs/GooglePlacesAutocomplete';
import TextField from './NewLayout/Textfield';

interface IEditableTableCellProps {
  value: string;
  onEditComplete: (val: string) => void;
  type?: 'text' | 'number' | 'date' | 'time' | 'file';
  min?: string;
  max?: string;
  isDropdown?: boolean;
  options?: { value: string; id: string | number }[];
  id?: string | number;
  apiInProgress?: boolean;
  setApiInProgress: (val: boolean) => void;
  displayValue?: string;
  isAddress?: boolean;
  selectText?: boolean;
  name?: string;
  maxlength?: number;
  isPrice?: boolean;
}

function EditableTableCell({
  value,
  onEditComplete,
  type,
  min,
  max,
  isDropdown,
  options,
  id,
  apiInProgress,
  setApiInProgress,
  displayValue,
  selectText,
  isAddress,
  name,
  maxlength,
  isPrice
}: IEditableTableCellProps) {
  const [val, setVal] = useState<string | number>('');
  const [isDropdownOptionListVisible, setDropdownOptionListVisibility] = useState<boolean>(false);
  const ref = useRef(null);
  const dropdownRef = useRef<HTMLSelectElement>(null);
  const latestVal = useRef<string | number>(val);
  const previousVal = useRef<string | number>(value);
  useEffect(() => {
    setVal(id || value);
    previousVal.current = value;
  }, [id, value]);

  useEffect(() => {
    latestVal.current = val;
  }, [val]);

  useEffect(() => {
    function handleClickOutside(event) {
      const isInput =
        event.target.matches('.input-element') || event.target.closest('.input-element');
      if (ref.current && !isInput) {
        handleOnBlur(isDropdown ?? false, event);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);

  useEffect(() => {
    // Focus dropdown if apiInProgress is true and it's a dropdown
    if (apiInProgress && isDropdown && dropdownRef.current) {
      dropdownRef.current.focus();
    }
  }, [apiInProgress, isDropdown]);

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    let newValue = target.value;

    const validateAndSetValue = (regex: RegExp) => {
      if (regex.test(newValue)) {
        if (newValue.startsWith('.')) {
          newValue = `0${newValue}`;
        }
        if (newValue.replace('.', '').length <= 11) {
          setVal(newValue);
          setDropdownOptionListVisibility(false);
        }
      }
    };

    if (isDropdown) {
      setDropdownOptionListVisibility(false);
    }

    const MAX_10_DIGITS_ONE_DECIMAL = [
      'material_quantity',
      'labour_quantity',
      'itemQuantity',
      'labour_hours_per',
      'quantity'
    ];
    const MAX_11_DIGITS_TWO_DECIMALS = [
      'itemPrice',
      'material_unit_cost',
      'width',
      'length',
      'labour_per_unit_hours',
      'labour_per_unit_days',
      'job_value'
    ];

    if (MAX_10_DIGITS_ONE_DECIMAL.includes(name)) {
      validateAndSetValue(MAX_10_DIGITS_ONE_DECIMAL_REGEX);
    } else if (MAX_11_DIGITS_TWO_DECIMALS.includes(name)) {
      validateAndSetValue(MAX_11_DIGITS_TWO_DECIMALS_REGEX);
    } else if (name === 'estimate_item_type_id') {
      setVal(newValue === '' || newValue === 'Choose One' ? previousVal.current : newValue);
    } else if (name === 'hours') {
      validateAndSetValue(HOURS_VALIDATION_REGEX);
    } else {
      setVal(newValue);
      setDropdownOptionListVisibility(false);
    }
  };

  const handleClick = () => {
    setApiInProgress(true);
    setDropdownOptionListVisibility(true);
  };

  const handleOnBlur = (isValId: boolean, event: any) => {
    if (latestVal.current == val) {
      const v = isValId ? id : value;
      if (event.relatedTarget === ref.current) {
        return;
      }
      if (val === previousVal.current) {
        setApiInProgress(false);
        return;
      }
      let refVal = latestVal.current;
      if (v != refVal) {
        refVal = refVal === '' ? ' ' : refVal.toString();
        onEditComplete(refVal);
      } else {
        setApiInProgress(false);
      }
    }
  };

  return apiInProgress ? (
    <div className="input-div" ref={ref}>
      {isDropdown ? (
        <DropDown
          name={name || 'editable-cell-dropdown'}
          value={val.toString()}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => handleOnBlur(true, e)}
          // onKeyDown={(e) => {
          //   if (e.key === 'Enter') {
          //     handleOnBlur(true, e);
          //   }
          // }}
          data={options}
          isDropdownOptionListVisible={isDropdownOptionListVisible}
        />
      ) : isAddress ? (
        <GooglePlacesAutocompleteComp
          value={val?.toString()}
          onChange={handleChange}
          onBlur={(e) => {
            handleOnBlur(false, e);
          }}
          fromEditableTableCell
          name={'editableCell'}
        />
      ) : (
        <TextField
          className={'form-control input-element'}
          type={type}
          min={min}
          autoFocus
          max={max}
          maxlength={maxlength}
          name="editable-cell-input"
          value={val?.toString() ?? ''}
          onChange={(e) => handleChange(e)}
          onBlur={(e) => {
            handleOnBlur(false, e);
          }}
          selectText={selectText}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleOnBlur(false, e);
            } else if (name === 'job_value') {
              if (e.key === '-' || e.key === '+') {
                e.preventDefault();
              }
            }
          }}
        />
      )}
    </div>
  ) : (
    <div style={{ width: '100%', minHeight: '20px' }} onClick={handleClick}>
      {displayValue || value}
    </div>
  );
}

export default React.memo(
  EditableTableCell,
  (prevProps, nextProps) =>
    prevProps.apiInProgress === nextProps.apiInProgress && prevProps.value === nextProps.value
);
