import AWS from 'aws-sdk';
import config from 'config/index';

export default async function UploadImage(file, path?: string) {
  const S3_BUCKET = config.awsS3AssetsBucketName;
  // S3 Region
  const REGION = config.awsRegion;
  // S3 Credentials
  AWS.config.update({
    accessKeyId: localStorage.getItem('accessKeyId') ?? '',
    secretAccessKey: localStorage.getItem('secretAccessKey') ?? '',
    sessionToken: localStorage.getItem('sessionToken') ?? ''
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION
  });
  // Files Parameters
  const params = {
    Bucket: S3_BUCKET,
    Key: path ? path : file.name,
    ContentType: file.type,
    Body: file
  };

  // Uploading file to s3

  const upload = s3
    .putObject(params)
    .on('httpUploadProgress', (evt) => {
      console.log('Uploading ' + (evt.loaded * 100) / evt.total + '%');
    })
    .promise();

  await upload.then((err) => {
    console.log(err);
  });
}
