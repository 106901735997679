import React, { useEffect, useRef, useState } from 'react';
import type { KeyboardEvent } from 'react';
import ErrorMessage from './ErrorMessage';
import { TextField, useTheme } from '@mui/material';

export default function CustomTextfield({
  labelText,
  name,
  value,
  onChange,
  selectText,
  isRequired,
  placeholder,
  onKeyPress,
  type,
  disabled,
  maxlength,
  id,
  min,
  max,
  validator,
  className,
  validationString,
  dontDisplayErrorMsg,
  isTextarea,
  onBlur,
  InputProps,
  size,
  rows = 5,
  padding,
  autoFocus,
  prefix,
  multiple,
  fileType,
  focused
}: {
  labelText?: string;
  name: string;
  value?: string;
  onChange?: any;
  selectText?: boolean;
  isRequired?: boolean;
  placeholder?: string;
  onKeyPress?: (e: KeyboardEvent) => void;
  type?: 'text' | 'number' | 'date' | 'file' | 'time';
  disabled?: boolean;
  maxlength?: number;
  id?: string;
  min?: string;
  max?: string;
  validator?: any;
  className?: string;
  validationString?: string;
  dontDisplayErrorMsg?: boolean;
  isTextarea?: boolean;
  onBlur?: any;
  InputProps?: any;
  size?: 'small' | 'medium';
  padding?: any;
  autoFocus?: boolean;
  rows?: number;
  prefix?: any;
  fileType?: string;
  multiple?: boolean;
  focused?: boolean;
}) {
  const isError = isRequired
    ? validator?.current?.message(name, value, validationString ? validationString : 'required')
    : false;

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleFocus = (e) => {
    e.currentTarget.select();
  };

  return (
    <>
      <div style={{ position: 'relative' }}>
        {isTextarea ? (
          <TextField
            className={`${className || ''} `}
            error={isRequired && isError}
            placeholder={placeholder ?? ''}
            value={value}
            name={name}
            multiline
            rows={rows}
            fullWidth
            size={size || 'medium'}
            onChange={onChange}
            disabled={disabled}
            label={labelText}
            inputProps={{
              maxlength
            }}
            {...(placeholder
              ? {
                  InputLabelProps: {
                    shrink: true
                  }
                }
              : {})}
            onBlur={onBlur}
            onKeyDown={onKeyPress}
          />
        ) : (
          <TextField
            className={` ${className || ''}`}
            type={type || 'text'}
            error={isRequired && isError}
            value={value}
            size={size || 'medium'}
            label={labelText}
            onChange={onChange}
            onKeyDown={onKeyPress}
            onBlur={onBlur}
            ref={inputRef}
            autoFocus={autoFocus}
            onFocus={selectText ? handleFocus : undefined}
            fullWidth
            focused={focused}
            sx={{ padding: 0 }}
            placeholder={placeholder}
            disabled={disabled ? true : false}
            id={id ?? name}
            name={name}
            inputProps={{
              min,
              max,
              maxlength,
              style: { padding },
              multiple,
              accept: { fileType }
            }}
            InputProps={{
              ...InputProps,
              style: { padding },
              startAdornment: prefix ? (
                <span style={{ marginRight: '8px', color: '#888' }}>{prefix}</span>
              ) : null
            }}
            {...(type === 'date' || type === 'time'
              ? {
                  InputLabelProps: {
                    shrink: true
                  }
                }
              : {})}
          />
        )}
        {isRequired && (
          <span
            style={{
              position: 'absolute',
              right: '10px',
              top: '5px',
              color: 'red'
            }}>
            *
          </span>
        )}
      </div>
      {!dontDisplayErrorMsg && isRequired && isError ? (
        <ErrorMessage
          validator={validator}
          value={value}
          label={name}
          validationString={validationString || ''}
        />
      ) : (
        <p />
      )}
    </>
  );
}
