import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import { estimateItem } from 'api/index';
import type { EstimateItem, PatchEstimateItemRequest } from 'api/types';
import stylusNoteIcon from 'assets/images/stylus_note.svg';
import EditableTableCell from 'components/EditableTableCell';
import CustomButton from 'components/NewLayout/Button';
import DataTable from 'components/NewLayout/Table';
import { useCanvasContext } from 'contexts/canvasContext';
import { useState } from 'react';
import toast from 'react-hot-toast';
import Canvas from './Canvas';

const QuantityAccordion = ({
  estimateItemId,
  data,
  setQuantity
}: {
  estimateItemId: number;
  data: EstimateItem[];
  setQuantity: (val: string) => void;
}) => {
  const [apiInProgress, setApiInProgress] = useState<Record<string, boolean>>({});
  const [estimateItemData, setEstimateItemData] = useState<EstimateItem[]>([
    {
      estimate_item_id: data[0].estimate_item_id,
      quantity: data[0].quantity,
      width: data[0]?.width ?? 0,
      length: data[0]?.length ?? 0,
      sqft: data[0]?.sqft,
      total_estimated_cost: data[0]?.total_estimated_cost
    }
  ]);
  const { canvasVisibility, handleCanvasVisibility } = useCanvasContext();

  const onEditingComplete = (name: string, value: string, cellId: string) => {
    setApiInProgress((prev) => ({ ...prev, [cellId]: false }));

    setEstimateItemData((prevList) => {
      const updatedWidth = name === 'width' ? Number(value) : Number(prevList[0].width);
      const updatedLength = name === 'length' ? Number(value) : Number(prevList[0].length);
      const updatedSqFt = (updatedWidth * updatedLength).toFixed(2);

      const updatedItem = {
        ...prevList[0],
        [name]: Number(Number(value).toFixed(2)),
        sqft: updatedSqFt
      };
      return [updatedItem, ...prevList.slice(1)];
    });

    if (data[0].estimate_item_id) {
      name === 'quantity' && setQuantity(value);
      const updatedWidth = name === 'width' ? Number(value) : Number(estimateItemData[0].width);
      const updatedLength = name === 'length' ? Number(value) : Number(estimateItemData[0].length);
      const updatedSqFt = (updatedWidth * updatedLength).toFixed(2);

      const dataToUpdate: PatchEstimateItemRequest = {
        http_method: 'PATCH',
        data: {
          estimate_item_id: data[0].estimate_item_id,
          [name]: Number(Number(value).toFixed(2)),
          sqft: Number(updatedSqFt),
          total_estimated_cost: 0
        }
      };
      estimateItem(dataToUpdate)
        .then((res) => {
          toast.success('Updated successfully!', {
            position: 'top-center',
            id: 'update_success'
          });
        })
        .catch((err) => {
          console.error('err', err);
        });
    }
  };

  const estimatesListColumns = [
    {
      name: 'Quantity',
      prop: 'quantity',
      data: (row: EstimateItem, id: number) => {
        const cellId = `width-${id}`;
        return (
          <EditableTableCell
            name="quantity"
            value={row.quantity?.toString() ?? '0'}
            type="number"
            onEditComplete={(val) => onEditingComplete('quantity', val, cellId)}
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Width',
      prop: 'width',
      data: (row: EstimateItem, id: number) => {
        const cellId = `width-${id}`;
        return (
          <EditableTableCell
            name="width"
            value={row.width ? `${row.width} ft` : '0'}
            type="number"
            onEditComplete={(val) => onEditingComplete('width', val, cellId)}
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Length',
      prop: 'length',
      data: (row: EstimateItem, id: number) => {
        const cellId = `length-${id}`;
        return (
          <EditableTableCell
            name="length"
            value={row.length ? `${row.length} ft` : '0'}
            type="number"
            onEditComplete={(val) => onEditingComplete('length', val, cellId)}
            key={cellId}
            apiInProgress={apiInProgress[cellId]}
            setApiInProgress={(val) =>
              setApiInProgress((prev) => ({
                ...prev,
                [cellId]: val
              }))
            }
            selectText
          />
        );
      }
    },
    {
      name: 'Sq. Ft.',
      prop: 'sqft',
      data: (row: EstimateItem) => row.sqft || '0'
    }
  ];

  return (
    <Accordion className="mui-accordion">
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography
          variant="h4"
          sx={{
            fontFamily: 'Poppins',
            fontWeight: 500,
            fontSize: '22px'
          }}>
          Quantity
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ borderTop: '1px solid #000' }}>
        {/* 1st section  */}
        <Box sx={{ my: 2 }}>
          <DataTable
            items={estimateItemData}
            columns={estimatesListColumns}
            totalCount={0}
            serverSidePagination={false}
          />
        </Box>

        {/* 2nd section  */}
        {canvasVisibility.quantity ? (
          <Box sx={{ my: 2, textAlign: 'center' }}>
            <Canvas estimateItemId={estimateItemId} />
          </Box>
        ) : (
          <Box sx={{ my: 2, textAlign: 'center' }}>
            <CustomButton
              sx={{
                border: '1px dashed #335d87',
                backgroundColor: '#fff',
                color: '#335d87',
                '&:hover': { backgroundColor: '#fff' }
              }}
              onClick={() => handleCanvasVisibility('quantity')}
              label={
                <>
                  <img src={stylusNoteIcon} alt="Icon" style={{ marginRight: '5px' }} />
                  <span>Move Canvas Here</span>
                </>
              }
            />
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default QuantityAccordion;
