import { Add, Search } from '@mui/icons-material';
import { Box, Button, Grid, IconButton, InputAdornment, SvgIcon } from '@mui/material';
import dropBoxIcon from 'assets/images/dropbox.svg';
import excelIcon from 'assets/images/excel.svg';
import filterIcon from 'assets/images/filterIcon.svg';
import saveIcon from 'assets/images/save.svg';
import CustomButton from 'components/NewLayout/Button';
import CustomTextfield from 'components/NewLayout/Textfield';
import { downloadExcel } from 'react-export-table-to-excel';

const EstimateItemSearchHeader = ({
  onChange,
  items,
  headerValues,
  setAddEstimateItemModal
}: {
  onChange: (data: string) => void;
  items: { [key: string]: any }[];
  headerValues: { [key: string]: number };
  setAddEstimateItemModal: (val: boolean) => void;
}) => {
  const headersForExcel = [
    'Estimate Item',
    'Quantity',
    'Total',
    'FAB MTLS',
    `Total MTLS: ${headerValues['total_mtls_percentage'] || 0.0}%`,
    'Non - Value',
    `Non-Value MTLS: ${headerValues['non_value_mtls_percentage'] || 0.0}%`,
    'Paint',
    `Fabrication: @${headerValues['fabrication_hours_per'] || 0.0}`,
    `Paint: @${headerValues['paint_hours_per'] || 0.0}`,
    `Media: @${headerValues['media_hours_per'] || 0.0}`,
    `Graphics: @${headerValues['graphics_hours_per'] || 0.0}`,
    `PM: @${headerValues['pm_hours_per'] || 0.0}`,
    `Design: @${headerValues['design_hours_per'] || 0.0}`,
    `Install: @${headerValues['install_hours_per'] || 0.0}`
  ];

  const bodyForExcel = items.map((item) => ({
    'Estimate Item': item.estimate_item_name,
    Quantity: item.quantity,
    Total: item.total ?? 0.0,
    'FAB MTLS': item.material_fabrication_total ?? 0.0,
    [`Total MTLS: ${headerValues['total_mtls_percentage']}`]:
      item.material_total_percentage ?? item.material_total,
    'Non - Value': item.non_value_total,
    [`Non-Value MTLS: ${headerValues['non_value_mtls_percentage']}`]:
      item.non_value_total_percentage ?? item.non_value_total,
    Paint: item.material_paint_total ?? 0.0,
    [`Fabrication: @${headerValues['fabrication_hours_per']}`]: item.fabrication_hours_per ?? 0.0,
    [`Paint: @${headerValues['paint_hours_per']}`]: item.paint_hours_per ?? 0.0,
    [`Media: @${headerValues['media_hours_per']}`]: item.media_hours_per ?? 0.0,
    [`Graphics: @${headerValues['graphics_hours_per']}`]: item.graphics_hours_per ?? 0.0,
    [`PM: @${headerValues['pm_hours_per']}`]: item.pm_hours_per ?? 0.0,
    [`Design: @${headerValues['design_hours_per']}`]: item.design_hours_per ?? 0.0,
    [`Install: @${headerValues['install_hours_per']}`]: item.install_hours_per ?? 0.0
  }));

  const handleDownloadExcel = () => {
    const now = new Date();
    const timestamp =
      `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(
        now.getDate()
      ).padStart(2, '0')} ` +
      `${String(now.getHours()).padStart(2, '0')}:${String(now.getMinutes()).padStart(
        2,
        '0'
      )}:${String(now.getSeconds()).padStart(2, '0')}`;

    const fileName = `oneXerp_excel_export_${timestamp.replace(/:/g, '-')}.xls`;
    downloadExcel({
      fileName,
      sheet: 'oneXerp - Estimate Items',
      tablePayload: {
        header: headersForExcel,
        body: bodyForExcel
      }
    });
  };
  return (
    <>
      <Box>
        <Grid container spacing={2} alignItems="center" justifyContent="space-between">
          <Grid item xs={12} sm={6} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 1 }}>
              <CustomTextfield
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon component={Search} />
                    </InputAdornment>
                  )
                }}
                name="search"
                placeholder="Quick Search"
                onChange={onChange}
              />
              {/* <Button
              onClick={() => console.log('Filter click')}
              sx={{
                borderRadius: '30px',
                padding: { xs: '5px 10px', md: '5px 15px' },
                backgroundColor: '#ebdadf',
                fontSize: { xs: '0.75rem', md: '0.875rem' },
                fontWeight: '600',
                display: 'flex',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                '&:hover': { backgroundColor: '#f6dfe6' }
              }}>
              <img src={filterIcon} alt="filter" width="15px" style={{ marginRight: '5px' }} />
              Filter Search
            </Button> */}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={8}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: { xs: 'center', sm: 'flex-end' },
                alignItems: 'center',
                gap: 2,
                flexWrap: 'wrap'
              }}>
              <Box sx={{ display: 'flex', gap: 1 }}>
                <IconButton>
                  <img style={{ width: '25px' }} src={dropBoxIcon} alt="Dropbox" />
                </IconButton>
                <IconButton>
                  <img
                    style={{ width: '25px' }}
                    src={excelIcon}
                    alt="Excel"
                    onClick={() => handleDownloadExcel()}
                  />
                </IconButton>
                <IconButton>
                  <img style={{ width: '25px' }} src={saveIcon} alt="Save" />
                </IconButton>
              </Box>
              <CustomButton
                onClick={() => setAddEstimateItemModal(true)}
                label={
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center'
                    }}>
                    <SvgIcon
                      sx={{
                        border: '1px solid #eee',
                        borderRadius: '50%',
                        marginRight: '10px',
                        width: { xs: '18px', md: '20px' },
                        height: { xs: '18px', md: '20px' }
                      }}
                      component={Add}
                    />
                    <Box
                      component="span"
                      sx={{
                        fontSize: { xs: '0.75rem', md: '0.875rem' },
                        fontWeight: '600'
                      }}>
                      Add Items
                    </Box>
                  </Box>
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EstimateItemSearchHeader;
