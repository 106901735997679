import { Box, Button, Grid, Typography } from '@mui/material';
import ChatArrow from 'assets/images/arrow_warm_up.svg';
import ChatIcon from 'assets/images/bot.svg';
import ChatIconImg from 'assets/images/chat-icon-img.svg';
import config from 'config/index';
import { useEffect, useState } from 'react';
import { useUserStore } from 'store/index';
import type { UserType } from 'store/useSlice';
import CustomDialog from './NewLayout/Dialog';
import TextField from './NewLayout/Textfield';

export interface MessageResponse {
  content?: string;
  role?: string;
  sender?: string;
  text?: string;
}

type MessageType = {
  text: string;
  sender: 'user' | 'bot';
};

const OPEN_AI_BASE_URL = 'https://api.openai.com/v1/threads';

const createHeaders = () => ({
  Authorization: `Bearer ${config.chatBotAPI}`,
  'Content-Type': 'application/json',
  'OpenAI-Beta': 'assistants=v2'
});

export const openAIService = {
  async handleFetchResponse(response: Response) {
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Request failed: ${errorText}`);
    }
    return response.json();
  },

  async createThread() {
    const response = await fetch(OPEN_AI_BASE_URL, {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({})
    });
    const data = await this.handleFetchResponse(response);
    return data.id;
  },

  async addMessageToThread(threadId: string, content: string) {
    const response = await fetch(`${OPEN_AI_BASE_URL}/${threadId}/messages`, {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        role: 'user',
        content
      })
    });
    return this.handleFetchResponse(response);
  },

  async executeRun(threadId: string) {
    const response = await fetch(`${OPEN_AI_BASE_URL}/${threadId}/runs`, {
      method: 'POST',
      headers: createHeaders(),
      body: JSON.stringify({
        assistant_id: 'asst_BeneqxJtFFVEFSryJwsT72x3',
        additional_instructions: null,
        tool_choice: null
      })
    });
    return this.handleFetchResponse(response);
  },

  async getRunStatus(threadId: string, runId: string) {
    const response = await fetch(`${OPEN_AI_BASE_URL}/${threadId}/runs/${runId}`, {
      method: 'GET',
      headers: createHeaders()
    });
    return this.handleFetchResponse(response);
  },

  async getLatestMessage(threadId: string) {
    const response = await fetch(`${OPEN_AI_BASE_URL}/${threadId}/messages`, {
      method: 'GET',
      headers: createHeaders()
    });
    const data = await this.handleFetchResponse(response);

    const assistantMessage = data.data.find((message: any) => message.role === 'assistant');
    if (!assistantMessage) {
      throw new Error('No assistant message found');
    }

    const textContent = assistantMessage.content.find(
      (contentItem: any) => contentItem.type === 'text'
    );
    if (!textContent) {
      throw new Error('No text content found in assistant message');
    }

    return textContent.text.value;
  }
};

export default function ChatModal({ onClose }: { onClose: () => void }) {
  const [messages, setMessages] = useState<MessageType[]>([]);
  const [threadId, setThreadId] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [input, setInput] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);
  const users: UserType[] = useUserStore((state) => state.users);

  useEffect(() => {
    if (localStorage?.getItem('chatThread')) {
      setThreadId(localStorage?.getItem('chatThread') ?? null);
    } else {
      const initThread = async () => {
        try {
          const id = await openAIService.createThread();
          setThreadId(id);
          localStorage.setItem('chatThread', id ?? '');
        } catch (err: any) {
          setError(err.message);
        }
      };
      initThread();
    }
  }, []);

  const handleSend = async () => {
    if (!input.trim() || !threadId) return;

    const userMessage: MessageType = { text: input, sender: 'user' };
    setMessages((prev) => [...prev, userMessage]);
    setIsTyping(true);
    setError(null);

    try {
      await openAIService.addMessageToThread(threadId, input);
      const runData = await openAIService.executeRun(threadId);
      await pollRunStatus(threadId, runData.id);
    } catch (err: any) {
      const errorMessage = err instanceof Error ? err.message : 'An unexpected error occurred';
      setError(errorMessage);
      setIsTyping(false);
    }

    setInput('');
  };

  const pollRunStatus = async (threadId: string, runId: string) => {
    try {
      const statusData = await openAIService.getRunStatus(threadId, runId);
      if (statusData.status === 'completed') {
        const botReply = await openAIService.getLatestMessage(threadId);
        const botMessage: MessageType = { text: botReply, sender: 'bot' };
        setMessages((prev) => [...prev, botMessage]);
        setIsTyping(false);
      } else if (statusData.status === 'failed') {
        throw new Error('Run failed');
      } else {
        await new Promise((resolve) => setTimeout(resolve, 2000));
        await pollRunStatus(threadId, runId);
      }
    } catch (err: any) {
      setError(err.message);
      setIsTyping(false);
    }
  };

  return (
    <>
      <CustomDialog
        open={true}
        closeDialog={() => onClose()}
        maxWidth="md"
        title="Chat Bot"
        content={
          <>
            <Box className="chatbox-div">
              {input != '' || messages.length > 0 ? (
                <>
                  <Box className="chatbox-content-div chatbox-messages-div">
                    {messages.length <= 0 ? (
                      <>
                        <Box component="span" className="d-flex chatbox-ml">
                          <img
                            src={ChatIcon}
                            className="img-fluid chat-icon"
                            alt="OneXerp Chat Icon"
                          />
                          <Box component="span" className="chatbox-ml">
                            <Typography className="chatbox-h5-margin">Bot</Typography>
                            <Typography variant="body2">
                              Sure go ahead, ask anything related to oneXerp.
                            </Typography>
                          </Box>
                        </Box>
                      </>
                    ) : (
                      <>
                        {messages.map((message: MessageResponse, index) => (
                          <Box key={index}>
                            <Typography variant="h4">
                              {message?.sender != 'user' ? (
                                <>
                                  <Box component="span" className="d-flex">
                                    <img
                                      src={ChatIcon}
                                      className="img-fluid chat-icon"
                                      alt="OneXerp Chat Icon"
                                    />
                                    <Box component="span" className="chatbox-ml">
                                      <Typography variant="h5" className="chatbox-h5-margin">
                                        Bot
                                      </Typography>
                                      <Typography variant="body2">{message?.text}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              ) : (
                                <>
                                  <Box component="span" className="d-flex">
                                    <Box component="span" className="chatbox-user-message">
                                      <Typography variant="h5" className="chatbox-h5-margin">
                                        {users[0]?.name ?? localStorage.getItem('userName')}
                                      </Typography>
                                      <Typography variant="body2">{message?.text}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              )}
                            </Typography>
                          </Box>
                        ))}{' '}
                      </>
                    )}
                  </Box>
                  {isTyping && (
                    <Typography variant="body2" className="chatbox-user-message">
                      Bot is typing...
                    </Typography>
                  )}
                </>
              ) : (
                <>
                  <Box className="chatbox-content-div" sx={{ textAlign: 'center' }}>
                    <img
                      src={ChatIconImg}
                      className="img-fluid data-table-img"
                      alt="Image placeholder"
                    />
                    <Typography variant="h4" className="chatbox-h3" sx={{ mt: 2 }}>
                      How can I help you today?
                    </Typography>
                  </Box>
                </>
              )}
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  if (input.trim() !== '') {
                    handleSend();
                    setInput('');
                    setIsTyping(true);
                  }
                }}
                className="d-flex justify-content-center chatbox-height">
                <Grid container sx={{ width: '70%' }}>
                  <Grid item sx={{ width: '85%' }}>
                    <TextField
                      name="chat"
                      value={input}
                      onChange={(e) => setInput(e.target.value)}
                      placeholder="Ask Ai Trainer...."
                      isRequired={false}
                      disabled={isTyping}
                    />
                  </Grid>

                  <Grid item alignItems="stretch" style={{ display: 'flex' }}>
                    <Button
                      color="secondary"
                      variant="contained"
                      disabled={isTyping}
                      type="submit"
                      sx={{ width: '75%', height: '76%', backgroundColor: '#C32051' }}>
                      <img
                        src={ChatArrow}
                        className="img-fluid data-table-img"
                        alt="Image placeholder"
                      />
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </>
        }
      />
    </>
  );
}
