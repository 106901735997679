import { Box, Typography, TextField, InputAdornment } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/splide/css';
import 'assets/css/style.css';
import { useState } from 'react';
import { MAX_11_DIGITS_TWO_DECIMALS_REGEX } from 'utils/index';

const headerTitles: DataItem[] = [
  {
    id: 'total_mtls_percentage',
    name: 'Total Mtls: '
  },
  {
    id: 'non_value_mtls_percentage',
    name: 'Non-Value Mtls: '
  },
  {
    id: 'fabrication_hours_per',
    name: 'Fabrication: '
  },
  {
    id: 'paint_hours_per',
    name: 'Paint: '
  },
  {
    id: 'media_hours_per',
    name: 'Media: '
  },
  {
    id: 'graphics_hours_per',
    name: 'Graphics: '
  },
  {
    id: 'pm_hours_per',
    name: 'PM: '
  },
  {
    id: 'design_hours_per',
    name: 'Design: '
  },
  {
    id: 'install_hours_per',
    name: 'Install: '
  }
];

interface DataItem {
  id: string;
  name: string;
  value?: number;
}

const HeaderSlider = ({
  handleHeaderValue
}: {
  handleHeaderValue: (id: string, value: number) => void;
}) => {
  const [headerSliderData, setHeaderSliderData] = useState(headerTitles);

  const handleValueChange = (id: string, value: number) => {
    if (MAX_11_DIGITS_TWO_DECIMALS_REGEX.test(value.toString())) {
      setHeaderSliderData((prevData) =>
        prevData.map((item) => (item.id === id ? { ...item, value } : item))
      );
      handleHeaderValue(id, value);
    }
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Splide
        style={{ overflow: 'hidden' }}
        options={{
          trimSpace: 'move',
          pagination: false,
          perPage: 3,
          arrows: true,
          autoWidth: true,
          type: 'loop',
          resetProgress: false
        }}
        className="splide-custom-arrows">
        {headerSliderData.map((item) => (
          <SplideSlide key={item.id} style={{ padding: '20px 0px', textAlign: 'start' }}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                backgroundColor: '#D1D5DB',
                borderRadius: '25px',
                padding: '12px 24px',
                marginLeft: '30px',
                color: '#1F2937'
              }}>
              <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                {item.name}
              </Typography>
              <TextField
                type="number"
                value={item.value || ''}
                onChange={(e) => handleValueChange(item.id, Number(e.target.value))}
                size="small"
                InputProps={{
                  endAdornment: item.id.includes('percentage') ? (
                    <InputAdornment
                      position="end"
                      sx={{
                        marginLeft: '-5px',
                        fontWeight: 'bold',
                        color: '#1F2937'
                      }}>
                      %
                    </InputAdornment>
                  ) : null
                }}
                sx={{
                  width: '70px',
                  borderRadius: '4px',
                  '& .MuiOutlinedInput-root': {
                    height: '30px',
                    '& input': {
                      padding: '1px',
                      textAlign: 'center'
                    },
                    '& fieldset': {
                      borderColor: 'darkblue'
                    },
                    '&:hover fieldset': {
                      borderColor: 'darkblue'
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: 'darkblue'
                    }
                  }
                }}
              />
            </Box>
          </SplideSlide>
        ))}
      </Splide>
    </Box>
  );
};

export default HeaderSlider;
